import React from "react";
import Icon from "./icon";
import {
  contacts,
  contacts__list,
  contacts__listItem,
  contacts__listItemLink,
} from "./contacts.module.scss";

const getContactHref = (name, contact) => {
  switch (name) {
    case "twitter":
      return `https://www.twitter.com/${contact}`;
    case "github":
      return `https://github.com/${contact}`;
    case "linkedin":
      return `https://www.linkedin.com/in/${contact}`;
    default:
      return "";
  }
};

const Contacts = ({ social }) => (
  <div className={contacts}>
    <ul className={contacts__list}>
      {Object.keys(social).map((name) => (
        <li className={contacts__listItem} key={name}>
          <a
            className={contacts__listItemLink}
            href={getContactHref(name, social[name])}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon name={name} />
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default Contacts;
