import React from "react";
import { Link } from "gatsby";
import {
  profile__photo,
  profile__title,
  profile__titleLink,
  profile__subtitle,
} from "./profile.module.scss";
import { StaticImage } from "gatsby-plugin-image";

// const getContactTitleAndLink = (name, contact) => {
//   switch (name) {
//     case "twitter":
//       return {title:"Twitter", link: `https://www.twitter.com/${contact}`};
//     case "github":
//       return {title:"GitHub", link: `https://github.com/${contact}`};
//     case "linkedin":
//       return {title:"LinkedIn", link: `https://www.linkedin.com/in/${contact}`};
//   }
// };

const Profile = ({ name, summary, isIndex }) => (
  <div>
    <Link to="/">
      <StaticImage
        className={profile__photo}
        imgClassName={profile__photo}
        style={{ borderRadius: '50%' }}
        imgStyle={{ borderRadius: '50%' }}
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.jpg"
        width={75}
        height={75}
        quality={95}
        alt="Profile picture"
        placeholder="none"
      />
    </Link>

    {isIndex === true ? (
      <h1 className={profile__title}>
        <Link className={profile__titleLink} to="/">
          {name}
        </Link>
      </h1>
    ) : (
      <h2 className={profile__title}>
        <Link className={profile__titleLink} to="/">
          {name}
        </Link>
      </h2>
    )}
    <p className={profile__subtitle}>{summary}</p>
    <p className={profile__subtitle}>
      You can also find me on{" "}
      <a
        href="https://twitter.com/ceyhun"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <strong>Twitter</strong>
      </a>
      ,{" "}
      <a
        href="https://github.com/ceyhun"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <strong>GitHub</strong>
      </a>{" "}
      and{" "}
      <a
        href="https://linkedin.com/in/ceyhunozugur"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <strong>LinkedIn</strong>
      </a>
      .
    </p>
  </div>
);

export default Profile;
