import * as React from "react";
import Layout from "../components/layout";
import SeoHelmet from "../components/seo-helmet";
import Sidebar from "../components/sidebar";
import Page from "../components/page";

const NotFoundPage = () => {
  return (
    <Layout>
      <SeoHelmet title="404: Not Found" />
      <Sidebar />
      <Page title="404: Not Found">
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Page>
    </Layout>
  );
};

export default NotFoundPage;
