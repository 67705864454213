import React from "react";
import Profile from "./profile";
import Contacts from "./contacts";
import Copyright from "./copyright";
import { sidebar, sidebar__inner } from "./sidebar.module.scss";
import { useStaticQuery, graphql } from "gatsby";

const Sidebar = ({ isIndex }) => {
  const {
    site: {
      siteMetadata: {
        author: { name, summary },
        social,
      },
    },
  } = useStaticQuery(graphql`
    query SidebarQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            github
            linkedin
          }
        }
      }
    }
  `);

  return (
    <div className={sidebar}>
      <div className={sidebar__inner}>
        <Profile
          name={name}
          summary={summary}
          social={social}
          isIndex={isIndex}
        />
        <Contacts social={social} />
        <Copyright />
      </div>
    </div>
  );
};

export default Sidebar;
