import React from "react";
import { page, page__inner, page__title, page__body } from "./page.module.scss";

const Page = ({ title, children }) => (
  <div className={page}>
    <div className={page__inner}>
      {title && <h1 className={page__title}>{title}</h1>}
      <div className={page__body}>{children}</div>
    </div>
  </div>
);

export default Page;
